/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

// markup
const MembershipPage = ({ data }) => {
  const bannerImage = getImage(data.graphCmsPagesMembership.featuredImage);

  return (
    <Layout>
      <SEO title="Contact" />
      <GatsbyImage image={bannerImage} alt="" />
      <Box sx={styles}>
        <h1>{data.graphCmsPagesMembership.title}</h1>
        <div
          className="bodyText"
          dangerouslySetInnerHTML={{
            __html: data.graphCmsPagesMembership.bodyText.html,
          }}
        />
      </Box>
    </Layout>
  );
};

export default MembershipPage;

export const query = graphql`
  query MembershipPageQuery {
    graphCmsPagesMembership(
      id: { eq: "PagesMembership:cksj4pbxk1fcy0a56q3q4x7sp:PUBLISHED" }
    ) {
      id
      title

      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          aspectRatio: 1.97
        )
        alt
      }
      bodyText {
        raw
        html
      }
    }
  }
`;

const styles = {
  mx: [3, 5],
  "& .bodyText": {
    fontSize: 3,
    mb: 4,
    a: {
      color: "house4",
      //px: 2,
      "&:hover": {
        bg: "house4",
        color: "white",
        textDecoration: "none",
      },
    },
  },
};
